exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-belongings-index-jsx": () => import("./../../../src/pages/belongings/index.jsx" /* webpackChunkName: "component---src-pages-belongings-index-jsx" */),
  "component---src-pages-choose-index-jsx": () => import("./../../../src/pages/choose/index.jsx" /* webpackChunkName: "component---src-pages-choose-index-jsx" */),
  "component---src-pages-explanation-index-jsx": () => import("./../../../src/pages/explanation/index.jsx" /* webpackChunkName: "component---src-pages-explanation-index-jsx" */),
  "component---src-pages-flow-index-jsx": () => import("./../../../src/pages/flow/index.jsx" /* webpackChunkName: "component---src-pages-flow-index-jsx" */),
  "component---src-pages-reason-index-jsx": () => import("./../../../src/pages/reason/index.jsx" /* webpackChunkName: "component---src-pages-reason-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */)
}

